@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&family=Sanchez:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Thinner & Round Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  @apply bg-slate-300 dark:bg-slate-600;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-400 dark:bg-slate-500;
}
::-webkit-scrollbar-track {
  @apply bg-slate-100;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

body {
  @apply bg-slate-100 dark:bg-slate-900;
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

div {
  color: #656565;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Antd Image Preview Fix */
.ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-image-mask {
  border-radius: 0.5rem;
}
/* Antd Image Preview Actions Fix */
.ant-image-mask-info {
  display: flex;
  align-items: center;
}
/* Image Cropper Border Fix */
.reactEasyCrop_Container {
  border-radius: 0.375rem;
}
