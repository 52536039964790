.description-text-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.description-text-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.description-text-7 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7; /* number of lines to show */
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
